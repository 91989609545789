import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  userId: any;
  userName: any;
  userNumber: any;
  lastSeen: any;
  userData: Array<any> = [];
  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userData')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    let local = JSON.parse(localStorage.getItem('userData'));
    return local;
  }

  /**
   *  Confirms if user is admin
   */
  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }

  /**
   *  Confirms if user is client
   */
  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  returnLocalStorageData(): Observable<any> {
    let local = JSON.parse(localStorage.getItem('userData'));
    return of(local);
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`/api/auth/login`, { email, password })
      .pipe(
        map(user => {
          if(user){
            
          let data = { userData: user.userData, token: user.userToken, moduleList:user.moduleList, permissions:user.permissions }
          localStorage.setItem('userData', JSON.stringify(data));

          // if(user.userData.roleId == 1){
          //   this.router.navigate(["dashboard"]);
          // } else if(user.userData.roleId == 2){
          //   this.router.navigate(["dashboard"]);
          // } else if(user.userData.roleId == 3){
          //   this.router.navigate(["dashboard"]);
          // }else {
          //   this.router.navigate(["/dashboard"]);
          // }  

          // login successful if there's a jwt token in the response
          // if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            // setTimeout(() => {
            //   this._toastrService.success(
            //     'You have successfully logged in.',
            //     ' Welcome, ' + user.userData.username + '!',
            //     { toastClass: 'toast ngx-toastr', closeButton: true }
            //   );
            // }, 2500);

            // notify
            this.currentUserSubject.next(user);
          // }

          return user;
        }
        })
      );
  }

  loginType(email: string, password: string) {
    return this._http
      .post<any>(`/api/auth/login-type`, { email, password })
      .pipe(
        map(user => {
          if(user){
            
          let data = { userData: user.userData, token: user.userToken, moduleList:user.moduleList, permissions:user.permissions }
          localStorage.setItem('userData', JSON.stringify(data));

          // if(user.userData.roleId == 1){
          //   this.router.navigate(["dashboard"]);
          // } else if(user.userData.roleId == 2){
          //   this.router.navigate(["dashboard"]);
          // } else if(user.userData.roleId == 3){
          //   this.router.navigate(["dashboard"]);
          // }else {
          //   this.router.navigate(["/dashboard"]);
          // }  

          // login successful if there's a jwt token in the response
          // if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in.',
                ' Welcome, ' + user.userData.username + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          // }

          return user;
        }
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    localStorage.removeItem('userData');
    this.currentUserSubject.next(null);
  }

  getRoleName(id) {
    return this._http.get<any>(`/api/role/${id}`)
  }

  logoutUser(id) {
    return this._http.post(`/api/auth/logout?id=${id}`, '')
  }

  isLoggedIn() {
    return !!localStorage.getItem('userData');
  }

  generateOtp(email: any) {
    return this._http.post<any>(`/api/auth/send-otp`, { email })
  }

  verifyOtp(userId, otp: number) {

    return this._http.post<any>(`/api/auth/verify-otp`, { userId, otp })
  }

  resendOtp(userId) {
    return this._http.post<any>(`/api/auth/resend-otp`, { userId })
  }

  changeForgotPassword(userData) {
    return this._http.post<any>(`/api/auth/update-password`, userData);
  }

  microsoft() {
    return this._http.get('api/auth/microsoft');
  }

  getUserList(selectedValue:any) {
    return this._http.get<any>(`/api/user/list?type=${selectedValue}`)
  }
  
  singleSignOn(code):Observable<any> {
    return this._http.post(`/api/auth/sso`, { code: code }).pipe(
      map(user => {
        if(user){
        let data = { userData: user['userData'], token: user['userToken'], moduleList:user['moduleList'], permissions:user['permissions'] };
        localStorage.setItem('userData', JSON.stringify(data));
        // this.currentUserSubject.next(user);
      }
      return user
}))
}

getAllUsers() {
  return this._http.get(`/api/user/all`)
}

saveUserData(data) {
  this.userId = data['0']['id'];
  this.userName = data['0']['name'];
  this.userNumber = data['0']['userNumber'];
  this.lastSeen = data['0']['lastSeen'];
}

globalUserLogin(email: string) {
  return this._http
    .post<any>(`/api/auth/global-user-login`, {email:email})
    .pipe(
      map(user => {
        if(user){

          this.logoutUser(user.userData.id);
          localStorage.clear();
          
        let data = { userData: user.userData, token: user.userToken, moduleList:user.moduleList, permissions:user.permissions }
        localStorage.setItem('userData', JSON.stringify(data));

        // if(user.userData.roleId == 1){
        //   this.router.navigate(["dashboard"]);
        // } else if(user.userData.roleId == 2){
        //   this.router.navigate(["dashboard"]);
        // } else if(user.userData.roleId == 3){
        //   this.router.navigate(["dashboard"]);
        // }else {
        //   this.router.navigate(["/dashboard"]);
        // }  

        // login successful if there's a jwt token in the response
        // if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(user));

          // Display welcome toast!
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in as an ' +
                user.userData.role +
                ' user to Logistics Portal.',
              ' Welcome, ' + user.userData.username + '!',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);

          // notify
          this.currentUserSubject.next(user);
        // }

        return user;
      }
      })
    );
}

loginTypeGlobal(email: string, type: string) {
  return this._http
    .post<any>(`/api/auth/global-login`, { email, type })
    .pipe(
      map(user => {
        if(user){
          
        let data = { userData: user.userData, token: user.userToken, moduleList:user.moduleList, permissions:user.permissions }
        localStorage.setItem('userData', JSON.stringify(data));

        // if(user.userData.roleId == 1){
        //   this.router.navigate(["dashboard"]);
        // } else if(user.userData.roleId == 2){
        //   this.router.navigate(["dashboard"]);
        // } else if(user.userData.roleId == 3){
        //   this.router.navigate(["dashboard"]);
        // }else {
        //   this.router.navigate(["/dashboard"]);
        // }  

        // login successful if there's a jwt token in the response
        // if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', JSON.stringify(user));

          // Display welcome toast!
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in.',
              ' Welcome, ' + user.userData.username + '!',
              { toastClass: 'toast ngx-toastr', closeButton: true }
            );
          }, 2500);

          // notify
          this.currentUserSubject.next(user);
        // }

        return user;
      }
      })
    );
}
}
